<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('account.validate_or_change.celular.' + data.action)"
          />
          <q-space />
          <q-btn
            flat
            round
            dense
            icon="close"
            color="red"
            v-close-popup
            @click="clearData()"
          />
        </q-card-section>
        <q-separator class="q-mr-md q-ml-md" />
        <div v-if="step == 0">
          <q-form @submit="handleCelular()" greedy>
            <q-card-section class="q-pt-none q-mt-md">
              <div
                class="bg-grey-4 q-pa-md text-justify q-mb-md default-rounded"
                v-html="$t('account.validate_or_change.celular.label_step_1')"
              />
              <q-input
                filled
                :label="'Novo celular'"
                mask="(##) #####-####"
                placeholder="(00) 00000-0000"
                v-model="newCelular"
                :rules="[
                  (v) => !!v || 'Campo obrigatório',
                  (v) => v.length == 15 || 'Celular inválido',
                ]"
                lazy-rules="ondemand"
              >
                <template v-slot:prepend>
                  <q-icon name="smartphone" />
                </template>
              </q-input>
            </q-card-section>
            <q-card-section class="q-pt-none" v-if="!isValidCelular">
              <div
                class="bg-red-2 q-pa-md text-justify q-mb-md"
                style="border-radius: 5px"
                v-html="$t(errorMessage)"
              />
            </q-card-section>
            <q-card-actions align="center">
              <q-btn
                unelevated
                color="dark"
                type="submit"
                :loading="in_request_validate"
                :label="$t('global.confirm')"
                class="text-weight-regular"
              />
            </q-card-actions>
          </q-form>
        </div>
        <div v-if="step == 1">
          <q-form @submit="submit()" greedy>
            <q-card-section class="q-pt-none q-mt-md">
              <div
                class="bg-grey-4 q-pa-md text-justify q-mb-md"
                style="border-radius: 5px"
                v-html="
                  $t('account.validate_or_change.celular.label_step_2', {
                    value: newCelular
                      ? newCelular
                      : formatCelular(data.data.value),
                  })
                "
              />
              <get-code-component @getCode="setCode($event)" />
            </q-card-section>
            <q-card-section class="q-pt-none q-mt-md q-mb-md">
              <div
                class="text-justify"
                :class="{
                  'text-grey-8': inTime > 0,
                  'text-primary': inTime == 0,
                }"
              >
                <span
                  @click="resend"
                  :style="`cursor: ${
                    inTime > 0 ? 'wait' : 'pointer'
                  }; font-size: 0.9em;`"
                  >Não recebeu o código? Clique aqui para reenviar.</span
                >
                {{ inTime ? `(${inTime}s)` : "" }}
              </div>
            </q-card-section>
            <q-card-section class="bg-grey-3 q-ma-md default-rounded">
              <div class="q-pa-xs text-justify" style="font-size: 0.95em">
                Está com dificuldades? Envie um email para
                <b>suporte@flip.net.br</b> e ajudaremos você.
              </div>
            </q-card-section>
            <q-card-actions align="center">
              <q-btn
                unelevated
                color="dark"
                type="submit"
                :loading="in_request_update"
                :disabled="code.length < 6"
                :label="$t('global.verify')"
                class="text-weight-regular"
              />
            </q-card-actions>
          </q-form>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useActions, useGetters } = createNamespacedHelpers("account");
import GetCodeComponent from "./GetCodeComponent.vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { validateCelular } from "./setup";

export default {
  name: "ValidateOrChangeCelular",

  components: {
    GetCodeComponent,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  emits: ["changeOrVerify"],

  setup(props, { emit }) {
    const { t } = useI18n();

    const code = ref("");
    const step = ref(0);
    const inTime = ref(0);
    const newCelular = ref("");
    const isValidCelular = ref(true);
    const in_request_validate = ref(false);
    const in_request_update = ref(false);
    const errorMessage = ref("O campo email já está sendo utilizado");

    const {
        fetchSendAndUpdateChallenge,
        fetchValidateChallenge,
        fetchPartnerChallenges,
        fetchResendChallenge,
      } = useActions([
        "fetchSendAndUpdateChallenge",
        "fetchValidateChallenge",
        "fetchPartnerChallenges",
        "fetchResendChallenge",
      ]),
      { challenge_code } = useGetters({ challenge_code: "getChallengeCode" });

    const handleCelular = () => validateInput(newCelular.value);

    function validateInput(value) {
      in_request_validate.value = true;
      validateCelular(value)
        .then(() => {
          isValidCelular.value = true;
          fetchSendAndUpdateChallenge({
            type: "sms",
            value: value.replace(/\D/g, ""),
          }).then(() => (step.value = 1));
        })
        .catch(({ errors }) => {
          errorMessage.value = errors.celular[0];
          isValidCelular.value = false;
        })
        .finally(() => (in_request_validate.value = false));
    }

    const submit = () => {
      in_request_update.value = true;

      const data =
        typeof challenge_code.value == "object"
          ? {
              type: "sms",
              code: code.value,
              hash: challenge_code.value.challenge_hash,
              secret: challenge_code.value.secret_key,
            }
          : {
              type: "sms",
              code: code.value,
              hash: challenge_code.value,
            };

      fetchValidateChallenge(data)
        .then((res) => {
          if (res.validated == true) {
            notifySuccess(
              props.data.action == "verify"
                ? t("account.validate_or_change.celular.verified")
                : t("account.validate_or_change.celular.updated")
            );
            fetchPartnerChallenges();
            emit("changeOrVerify");
          } else {
            notifyError(t("account.validate_or_change.invalid_code"));
          }
        })
        .catch(() => notifyError(t("account.validate_or_change.invalid_code")))
        .finally(() => (in_request_update.value = false));
    };

    const resend = () => {
      if (inTime.value > 0) return;
      inTime.value = 30;
      let intervalId = setInterval(() => {
        if (inTime.value > 0) {
          inTime.value--;
        } else clearInterval(intervalId);
      }, 1000);

      fetchResendChallenge({
        type: "sms",
        hash: challenge_code.value,
      }).then(() =>
        notifySuccess(t("account.validate_or_change.resended_code"))
      );
    };

    onMounted(() => {
      props.data.action == "verify" ? (step.value = 1) : (step.value = 0);
    });

    return {
      step,
      code,
      inTime,
      newCelular,
      errorMessage,
      isValidCelular,
      in_request_validate,
      in_request_update,
      submit,
      resend,
      handleCelular,
      setCode: (e) => (code.value = e),
      clearData: () => {
        step.value = 0;
        code.value = "";
        newCelular.value = "";
      },
      formatCelular: (celular) => {
        return `(${celular.slice(0, 2)}) ${celular.slice(2, 7)}-${celular.slice(
          7
        )}`;
      },
    };
  },
};
</script>

<style scoped>
.form {
  width: 460px;
}

@media screen and (max-width: 800px) {
  .form {
    width: 100%;
  }
}
</style>
